import { resolveAfter } from "./waiters.utils";

export const waitForFontsToLoad = async () => {
  if (document.fonts && document.fonts.ready) {
    try {
      return await Promise.any([document.fonts.ready, resolveAfter(1000)]);
    } catch (e) {
      return;
    }
  }
  return;
}