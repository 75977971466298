import React from 'react';
import { AnimateTextEnterByLineProps, useAnimateTextEnterByLine } from '../../hooks/useAnimateTextEnterByLine.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import { isString } from '../../utils/typeChecks.utils';
import Line from '../Line/Line';
import PageSection from '../PageSection/PageSection';
import Widowless from '../Widowless/Widowless';
import './QuoteSection.scss';

type QuoteSectionProps = {
  id: string,
  className: string,
  content: string | string[],
  addLineBefore?: boolean,
  addLineAfter?: boolean,
} & AnimateTextEnterByLineProps

const QuoteSection: React.FC<QuoteSectionProps> = props => {
  return <PageSection id={props.id} className={joinClassName('QuoteSection', props.className)} observeVisibility>
    {props.addLineBefore && <Line />}
    <div className="QuoteSectionInner">
      <QuoteSectionBlockquote {...props} />
    </div>
    {props.addLineAfter && <Line />}
  </PageSection>
}

const QuoteSectionBlockquote = (props: QuoteSectionProps) => {
  const ref = useObservableRef();
  useAnimateTextEnterByLine(ref, {
    ...props,
    animateTextEnter: { onlyWhenParentSectionVisible: true, delay: 380, }
  });
  return <blockquote ref={ref}>
    {isString(props.content) ?
      <Widowless>{props.content}</Widowless> :
      props.content.map((p, i) => <Widowless key={i}>{p}</Widowless>)
    }
  </blockquote>
}

export default QuoteSection;