import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import CTAButton from "../components/CTAButton/CTAButton"
import GatsbyImageAdaptor from "../components/GatsbyImageAdaptor/GatsbyImageAdaptor"
import PageSection from "../components/PageSection/PageSection"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import QuoteSection from "../components/QuoteSection/QuoteSection"
import Spacer from "../components/Spacer/Spacer"
import { REACT_APP_ORIGIN, useGetReactAppOrigin } from "../env"
import { PageComponent } from "../types/gatsby.types"
import { TracksterHomepageQuery } from "../types/generated"
import { ColorPalette } from "../utils/colors.utils"
import './index.scss'

const PageIndex: PageComponent = props => {

  // Example of a typed and named query (ideal scenario: avoid untyped queries! errors are a lot harder to detect)
  // const previewImage: PreviewImageQuery = useStaticQuery(graphql`
  // query PreviewImage {
  // etc

  //Follow the naming convetion above for the name and type of the query
  // Have codegen running as per README.md file instruct: it will generate a type for the query to generated.tsx which you must import to the file

  const appURL = useGetReactAppOrigin();

  const homepage: TracksterHomepageQuery = useStaticQuery(graphql`
    query TracksterHomepage {
      featuredImage: file(relativePath: {eq: "images/previews/trackster-timer.png"}) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      allPosts: allMdx(
        filter: {fileAbsolutePath: {regex: "/features/"}}
        ) {
        nodes {
          frontmatter {
            title
            description
            overline
            featured_image {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH)
              }
            }
            color
          }
          id
          slug
        }
      }
    }`
  )

  return <PageTemplate
    {...props} // always include
    className="PageIndex"
    title="Home"
    backgroundColor={ColorPalette.white}
    foregroundColor={ColorPalette.dark}
  >

    <PageSection id="PageIndexHeroSection" className="PageIndexHeroSection">
      <article>
        {/* <p ref={heroPRef}><strong>Project management, simplified.</strong></p> */}
        {/* <h1 ref={headingRef}>Intuitive Time-tracking + Kanbans</h1> */}
        <p><strong>Project management, simplified.</strong></p>
        <h1>Intuitive Time-tracking + Kanbans</h1>
        <CTAButton
          key={appURL}
          path={`${appURL ?? REACT_APP_ORIGIN}/auth/register`}
          title="Get your free trial"
          children="Get Your Free Trial"
        />
      </article>
      <Spacer size="lg" />
      <GatsbyImageAdaptor image={homepage.featuredImage} />
      {/* <GatsbyImageAdaptor image={query.featuredImage} /> */}
    </PageSection>

    <QuoteSection
      id="PageIndexQuoteSection"
      className="PageIndexQuoteSection"
      content="Combining easy-to-use time-tracking, insights and kanban-style project management into one single tool. Managing your workflow has never been this easy."
    />

    {/* <FeatureBlockList
      id="FeatureBlockListCompact"
      allMdx={homepage.allPosts}>
    </FeatureBlockList> */}

    {/* TODO: Create single pages for features */}

    {/* TODO: Create a component to house the feature boxes that appear in the homepage and in the single feature page */}

    {/* <FeatureBlock
      id="TimeTrackingFeatureBlock"
      title="Acurate, Transparent Time-Tracking"
      // overline="Track everything"
      // image={featurePageImages.timeTracking}
      // gifFilePath={timeTrackingGif}
      // videoFilePath={timeTrackingVideo}
      color={'blue'}
      // children={
      //   <div>
      //     <p>Increase productivity through real-time and manual time tracking. Our automatic timesheet software allows your company to stay on track of projects. That way you can bill your team accurately and keep team members accountable.</p>
      //   </div>
      // }
    ></FeatureBlock>

    <FeatureBlock
      id="VisualisePerformanceFeatureBlock"
      title="Easily Visualise Performance"
      // overline="See the big picture"
      color={'green'}
      // image={featurePageImages.visualisePerformance}
      // videoFilePath={visualisePerfomanceVideo}
      // children={
      //   <div>
      //     <p>We want you to see exactly how trackster.io is improving your company’s performance. Gantt Charts, Kanban Boards and Calendars visually display your work. Our automatic reporting functionality can clearly display your productivity in just a few clicks.</p>
      //   </div>
      // }
    ></FeatureBlock>

    <FeatureBlock
      id="BetterProductivityFeatureBlock"
      title="Better Productivity from Day One"
      // overline="Enhanced Kanbans"
      // image={featurePageImages.betterProductivity}
      // videoFilePath={kanbansVideo}
      color={'yellow'}
      // children={
      //   <div>
      //     <p>
      //       Our intuitive project management system is so simple that you can learn how to use it in just 3 minutes. Our team has pinpointed the features that your business actually needs, so you aren’t overloaded. This means there is no learning curve to overcome and you can reap the benefits of trackster.io from the get go.
      //     </p>
      //   </div>
      // }
    ></FeatureBlock> */}

  </PageTemplate>
}

export default PageIndex